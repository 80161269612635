import { Box, Grid, Link, Typography } from "@mui/material";

import moopetLogo from "../assets/Moopets logo 11.png";
import telegramLogo from "../assets/Telegram.png";
import twitterLoo from "../assets/TwitterX.png";
import SocialIcons from "../utils/SocialIcons";
// import cage from "../assets/cage 1.svg"
import cage from "../assets/Group 10.png";
import Headings from "../utils/Headings";
import Content from "../utils/Content";
import MemeCage from "./MemeCage";

export default function Footer() {
  return (
    <Box className="main-container">
      <Box sx={{ marginTop: "3em" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", mx: { xs: 1, md: 0 } }}>
              <Headings
                text="Moopetz are here to take over Memeworld!"
                variant="h3"
              />
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",

                justifyContent: "center",
                textAlign: "center",
                // marginTop: 4,
              }}
            >
              <Headings
                text="MEME CAGE"
                variant="h4"
                sx={{ fontWeight: 500, marginBottom: '20px' }}
              />
              {/* <Content text={"MEME JAIL"} /> */}
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12}>
            <img
              alt="Moopet Logo"
              src={cage}
              style={{ width: "100%", marginBottom: "2em" }}
            />
          </Grid> */}
          <MemeCage />
          <SocialIcons />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            marginTop: "0.9em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "2em",
          }}
        >
          {/* <Typography
          variant="body1"
          sx={{
            width: "90vw",
            fontFamily: "Blinker",
            fontSize: "1.5em",
            fontWeight: 600,
            lineHeight: "28.8px",
            textAlign: "center",
            mb: 3, // Add some margin bottom for spacing
            '@media (min-width: 390px)': {
              fontSize: "1em",
              lineHeight: "23px",
              width: "90vw",
            },
            '@media (min-width: 1366px)': {
              fontSize: "1em",
              lineHeight: "26px",
              width: "90vw",
            },
            '@media (min-width: 1440px)': {
              fontSize: "1.2em",
              lineHeight: "27px",
              width: "90vw",
            },
            '@media (min-width: 1920px)': {
              fontSize: "1.5em",
              lineHeight: "31px",
              width: "90vw",
            },
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Odio urna ultrices rhoncus
          pulvinar lectus lacus ac massa.
        </Typography> */}

          <Typography
            variant="body1"
            sx={{
              width: "90vw",
              fontFamily: "Blinker",
              fontSize: "1em",
              fontWeight: 600,
              lineHeight: "31px",
              textAlign: "center",
              mb: 2, // Add some margin bottom for spacing

              "@media (min-width: 370px)": {
                fontSize: "1em",
                lineHeight: "23px",
                width: "90vw",
              },
              "@media (min-width: 1366px)": {
                fontSize: "1em",
                lineHeight: "26px",
                width: "90vw",
              },
              "@media (min-width: 1440px)": {
                fontSize: "1.2em",
                lineHeight: "27px",
                width: "90vw",
              },
              "@media (min-width: 1920px)": {
                fontSize: "1.5em",
                lineHeight: "31px",
                width: "90vw",
              },
            }}
          >
            $MPETZ is a meme coin with no intrinsic value of financial gain.
            There is no formal team or set roadmap. $MPETZ coin is completely
            useless and for entertainment purposes.
          </Typography>

          <Typography
            sx={{
              width: "30vw",
              fontFamily: "Jost",
              fontSize: "1em",
              fontWeight: 500,
              lineHeight: "28.9px",
              textAlign: "center",
              // mt: 1,
              "@media (min-width: 250px)": {
                fontSize: "0.6em",
                lineHeight: "20.9px",
                width: "90vw",
              },
              "@media (min-width: 375px)": {
                fontSize: "0.7em",
                lineHeight: "28.9px",
                width: "80vw",
              },
              "@media (min-width: 1366px)": {
                fontSize: "1em",
                lineHeight: "28.9px",
                width: "30vw",
              },
              "@media (min-width: 1440px)": {
                fontSize: "1em",
                lineHeight: "28.9px",
                width: "30vw",
              },
              "@media (min-width: 1920px)": {
                fontSize: "1em",
                lineHeight: "28.9px",
                width: "30vw",
              },
            }}
          >
            © 2024 by mootpetz. All right reserved!
            <br />
            <Link
              href="mailto:contact@moopetz.com"
              sx={{ color: "#5C1FAB", textDecoration: "none" }}
            >
              contact@moopetz.com
            </Link>
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
}
