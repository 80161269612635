

import React from "react";
import { Box, Typography } from "@mui/material";

const TokenInfoCard = (props:{title:string, content:any, style?:any}) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 0px 50px 20px rgba(255, 254, 248, 0.18)",
        borderRadius: "0px 30px 0px 30px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "2px solid rgba(255, 254, 248, 0.9)",
        padding: 2,
        my:{xs:3 , md:3, lg:4 , xl:10},
        // my:"2em"
        ...props.style

      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Inter",
          fontSize: "23px",
          lineHeight: "39px",
          textShadow:
            "-1px -1px 0px rgba(0, 0, 0, 0.5), -1px 1px 0px rgba(0, 0, 0, 0.5), 1px 1px 0px rgba(0, 0, 0, 0.5), 1px -1px 0px rgba(0, 0, 0, 0.5)",
          "@media (min-width: 390px)": {
            fontSize: "0.7em",
            lineHeight: "20px",
          },
          "@media (min-width: 1024px)": {
            fontSize: "23px",
            lineHeight: "39px",
          },
          "@media (min-width: 1366px)": {
            fontSize: "23px",
            lineHeight: "39px",
          },
          "@media (min-width: 1440px)": {
            fontSize: "23px",
            lineHeight: "39px",
          },
          "@media (min-width: 1920px)": {
            fontSize: "23px",
            lineHeight: "39px",
          },
        }}
      >
       {props.title}
      </Typography>
      <Typography
        sx={{
          fontSize: "19px",
          lineHeight: "31.73px",
          fontFamily: "Blinker",
          "@media (min-width: 390px)": {
            fontSize: "0.7em",
            lineHeight: "20px",
          },
          "@media (min-width: 1024px)": {
            fontSize: "19px",
            lineHeight: "31.73px",
          },
          "@media (min-width: 1366px)": {
            fontSize: "19px",
            lineHeight: "31.73px",
          },
          "@media (min-width: 1440px)": {
            fontSize: "19px",
            lineHeight: "31.73px",
          },
          "@media (min-width: 1920px)": {
            fontSize: "19px",
            lineHeight: "31.73px",
          },
        }}
      >
     {props.content}
      </Typography>
    </Box>
  );
};

export default TokenInfoCard;
