import React from "react";
import { Typography, SxProps } from "@mui/material";

interface HeadingsProps {
  text: any;
  sx?: SxProps;
}

const Content: React.FC<HeadingsProps> = ({ text, sx }) => {
  return (
    <Typography
      sx={{
        padding:4,
        fontFamily: "Blinker",
        fontWeight:600,
        // fontSize: "10px",
        fontSize: {xs:"20px" , md:"24px"},
        lineHeight:"28px",
        width:{sm:"70%" , md:"60%"},
        textAlign:"center",
        // textAlign:"justify",
        justifyContent:"center",
       
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export default Content;
