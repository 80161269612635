import React from "react";
import { Typography, SxProps } from "@mui/material";

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit';

interface HeadingsProps {
  text: string;
  sx?: SxProps;
  variant?:TypographyVariant;
}

const Headings: React.FC<HeadingsProps> = ({ text, sx , variant="h2"  }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: "Cherry Bomb",
        marginTop:4,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export default Headings;
