import { Box, Button, Grid, Typography } from "@mui/material";

import moopetGroup from "../assets/banner_petz.png";
import { handleScroll } from "../utils/utils";
import Headings from "../utils/Headings";
import Content from "../utils/Content";
import CustomButton from "../utils/Button";
import logo from "../assets/logo.png";

export default function MoopetzSection() {
  const openLink = () => {
    window.open(
      "https://raydium.io/swap/?inputMint=sol&outputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
      "_blank"
    );
  };
  return (
    <Box sx={{ width: "100%", marginTop: { xs: 10, md: 15 } }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={12} style={{marginTop: "-100px"}}>
          <Headings text="The MooPetz" sx={{display: { xs: "block", md: "none" },}}/>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            display: { xs: "block", md: "block" },
            pr: { xs: 2, md: 1 },
          }}
        >
          <img
            style={{ width: "100%", margin: "0.5em", marginBottom: "20px" }}
            alt="Moopetz"
            src={moopetGroup}
          />
        </Grid>

        {/* <Box sx={{ display: { xs: "flex", lg: "none" }, mr: 1 }}>
            <img
              src={logo}
              alt="Custom Icon"
              style={{
                height: "4.113rem",
                width: "4.375rem",
              }}
            />
          <Typography
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", lg: "none" },
              flexGrow: 1,
              fontFamily: "Cherry Bomb !important",
              fontWeight: 500,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: "1.2em",
            }}
          >
            The MooPetz
          </Typography>
          </Box> */}

        <Grid item xs={12} sm={12} md={12} style={{marginTop: "-40px", marginBottom: "-20px"}}>
          <Headings variant="h4" text="The MooPetz" sx={{display: { xs: "none", md: "block" },}}/>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            minWidth: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Headings
            text="Welcome to Moo Lab!"
            variant={"h3"}
            sx={{paddingBottom: 4, marginTop: '30px'}}
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex", // Added display flex to center items vertically
            flexDirection: "column", // Column direction to stack Typography elements
            alignItems: "center", // Center align items horizontally
            textAlign: "center", // Center align text
            justifyContent: "center",
          }}
        >
          <CustomButton onClick={openLink} text="Buy Tokens" />
        </Grid> */}
      </Grid>
    </Box>
  );
}
