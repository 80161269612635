import About from "../components/About";
import ResponsiveAppBar from "../components/Appbar";
import Buy from "../components/Buy";
import Footer from "../components/Footer";
import MoopetzSection from "../components/MoopetzSection";
import Navbar from "../components/Navbar";
import RoadMap from "../components/RoadMap";

import "./home.css";

export default function Home() {
  return (
    <div className="HomePage-Parent">
      {/* <Navbar/> */}
      <ResponsiveAppBar />

      <MoopetzSection />

      <About />

      <Buy />

      <RoadMap />

      <Footer />
    </div>
  );
}
