import { Box, Button, Grid, Typography, Link } from "@mui/material";
import "./buy.css";

import moonImage from "../assets/tomoon.png";
import ufo from "../assets/ufo-moo.png";
import ContentContainer from "../utils/ContentContainer";
import SocialIcons from "../utils/SocialIcons";
import UFO from "./Ufo";

// import chamberImage from "../assets/CryoChamber.png";

import gifImage from "../assets/a.gif";
import VideoWithLoading from "../utils/Video";
import Headings from "../utils/Headings";
import CustomButton from "../utils/Button";
import DexScreener from "../utils/dexWidget";

export default function Buy() {
  const openLink = () => {
    window.open(
      "https://raydium.io/swap/?inputMint=sol&outputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
      "_blank"
    );
  };
  return (
    <Box className="main-container">
      <Grid
        container
        sx={{
          display: "flex",
          direction: "column",
          alignItems: "center",
          justifyContent: "center",
          // marginTop:"2em !important"
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Headings text="How to Buy" />
        </Grid>
      </Grid>

      <UFO />

      <Box
        sx={{
          pt: 5,
          display: "flex",
          justifyContent: "center", // Center align horizontally
          alignItems: "center", // Center align vertically
        }}
      >
        <Box
          id={"how-to-buy"}
          sx={{
            px: { xs: 1, md: 'auto'},
            mx: { xs: 1, md: 'auto'},
            width: { xs: "100%", md: "40vw", xl: "50vw", xxl: "60vw" },
            height: { xs: "65vh", md: "70vh" },
          }}
        >
          <iframe
            style={{
              height: "100%",
              width: "100%",

              borderRadius: "10px", // Rounded corners
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Box shadow
              top: 0,
              left: 0,
            
              border: 0,
            }}
            // src="https://dexscreener.com/solana/9vJXrmANXNEPxZUV7mm9MMzXA4RHGNTs7riNG6wuyPGT?embed=1&theme=dark&trades=0"
            src="https://raydium.io/swap/?outputMint="
            title="DexScreener Embed"
          ></iframe>
        </Box>
      </Box>

      <Box sx={{ px: "4em", py: 7 }} id="buy-tokens">
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", flexdirection: { xs: "column", md: "row" } }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              my: 2,
            }}
          >
            {/* <img alt=""  src={gifImage} style={{
            
            height:'100%',
            width:'100%',
            objectFit:"cover"
            }}/> */}

            <Box
              component="img"
              alt="image"
              src={gifImage}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              // sx={{
              //   '@media (min-width: 320px)': {
              //     width: "10em",

              //   },
              //   '@media (min-width: 390px)': {
              //     width: "12.5em",

              //   },
              //   '@media (min-width: 1366px)': {
              //     width: "18.5em",
              //   },
              //   '@media (min-width: 1440px)': {
              //     width: "22.5em",
              //   },
              //   '@media (min-width: 1920px)': {
              //     width: "24.5em",
              //   },
              // }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              display: "flex", // Added display flex to center items vertically
              flexDirection: "column", // Column direction to stack Typography elements
              alignItems: "center", // Center align items horizontally
              textAlign: "center", // Center align text
              justifyContent: "center",
            }}
          >
            <CustomButton
              onClick={openLink}
              text="Buy Tokens"
              sx={{ my:{xs:2 , md:"auto"}  }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}
          >
            <Box
              component="img"
              alt="image"
              src={moonImage}
              sx={{
                width: { xs: "100%", md: "100%" , lg: "auto" },
                height: { xs: "100%", md: "auto" },
                // width: "100%",
                // height: "100%",
                objectFit: "cover",
              }}

              // sx={{
              //   '@media (min-width: 320px)': {
              //     width: "10em",

              //   },
              //   '@media (min-width: 390px)': {
              //     width: "12.5em",

              //   },
              //   '@media (min-width: 1366px)': {
              //     width: "18.5em",
              //   },
              //   '@media (min-width: 1440px)': {
              //     width: "22.5em",
              //   },
              //   '@media (min-width: 1920px)': {
              //     width: "24.5em",
              //   },
              // }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
