

import React from "react";
import { Button, useTheme ,SxProps } from "@mui/material";

interface CustomButtonProps {
  onClick?: () => void;
  text:string;
  sx?:SxProps
}

const CustomButton: React.FC<CustomButtonProps> = ({ onClick ,text  , sx }) => {
  const theme = useTheme();

  return (
    <Button
    sx={{
      fontFamily: "Jost",
      fontSize: "0.9em",
     
      fontWeight: 300,
      // width: "11vw",
      borderRadius: "2em",
      backgroundColor: "#5C1FAB",
      color: "white",
      textTransform: "none", // Prevents text from being transformed to uppercase
      "&:hover": {
        backgroundColor: "#4A178C",
      },
px:4,
      // width:{xs:"40vw", md:"12vw"},
      ...sx
      // '@media (min-width: 320px)  and (max-width: 599px)': {
      //   width: "50vw",
      //   fontSize: "1em",
      // },
      // '@media (min-width: 600px) and (max-width: 959px)': {
      //   width: "42vw",
      //   fontSize: "1.1em",
      // },
      // '@media (min-width: 960px) and (max-width: 1279px)': {
      //   width: "40vw",
      //   fontSize: "1.3em",
      // },
      // '@media (min-width: 1280px) and (max-width: 1919px)': {
      //   width: "11vw",
      //   fontSize: "0.9em",
      // },
      // '@media (min-width: 1920px)': {
      //   width: "11vw",
      //   fontSize: "1em",
      // },

    }}
    onClick={onClick}
  >
   {text}
  </Button>
  );
};

export default CustomButton;
