import React, { useEffect, useRef } from 'react';
import './MemeCage.css';
import coin1 from "../assets/memes/coin1.png";
import coin2 from "../assets/memes/coin2.png";
import coin3 from "../assets/memes/coin3.png";
import coin4 from "../assets/memes/coin4.png";
import coin5 from "../assets/memes/coin5.png";
import coin6 from "../assets/memes/coin6.png";
import coin7 from "../assets/memes/coin7.png";
import coin8 from "../assets/memes/coin8.png";
import coin9 from "../assets/memes/coin9.png";
import coin10 from "../assets/memes/coin10.png";
import coin11 from "../assets/memes/coin11.png";
import coin12 from "../assets/memes/coin12.png";
import coin13 from "../assets/memes/coin13.png";
import coin14 from "../assets/memes/coin14.png";
import coin15 from "../assets/memes/coin15.png";
import coin16 from "../assets/memes/coin16.png";
import cage from "../assets/cage.png";

const coins = [coin1, coin2, coin3, coin4, coin5, coin6, coin7, coin8, coin9, coin10, coin11, coin12, coin13, coin14, coin15, coin16];

export default function MemeCage() {
    const refContainer = useRef<HTMLImageElement[]>([]);

    const moveImage = (index: number) => {
        if (refContainer.current[index]) {
            const containerWidth = refContainer.current[index].parentElement?.clientWidth || 0;
            const containerHeight = refContainer.current[index].parentElement?.clientHeight || 0;
            const imageWidth = refContainer.current[index].clientWidth;
            const imageHeight = refContainer.current[index].clientHeight;
    
            const maxX = containerWidth - imageWidth;
            const maxY = containerHeight - imageHeight;
            const newX = Math.random() * maxX;
            const newY = Math.random() * maxY;
    
            refContainer.current[index].style.transform = `translate(${newX}px, ${newY}px)`;
        }
    };

    useEffect(() => {
        coins.forEach((_, index) => moveImage(index));
        const interval = setInterval(() => {
            coins.forEach((_, index) => moveImage(index));
        }, 2000); // Adjust time as needed

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="cage-container">
            {coins.map((coin, index) => (
                <img
                    key={index}
                    src={coin}
                    className='meme-coin-img'
                    alt='meme-coin'
                    ref={el => {
                      if (el) refContainer.current[index] = el;
                    }}
                />
            ))}
            <img
                    src={cage}
                    className='meme-cage-img'
                    alt='meme-coin'
                />
        </div>
    );
}
